<template>
  <div
    class="su-licenses"
    @click="$emit('close')"
  >
    <button
      class="su-licenses__close m-btn"
      @click="$emit('close')"
    >
      <img
        src="/v2/school-university/close.svg"
        alt="cross"
      >
    </button>

    <div
      class="su-licenses__slider"
      @click.stop
    >
      <img
        class="su-licenses__slide"
        src="/v2/main/sfe/license-1.webp"
        alt="license 1"
      >
      <img
        class="su-licenses__slide"
        src="/v2/main/sfe/license-2.webp"
        alt="license 2"
      >
      <img
        class="su-licenses__slide pointer"
        src="/v2/main/sfe/license-3.webp"
        alt="license 3"
        @click.stop="openDoc"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuLicensesModal',
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },
  methods: {
    openDoc() {
      window.open('/v2/main/sfe/Реестровая выписка.pdf', '_blank');
    },
  },
};
/* eslint-disable */
</script>

<style scoped lang="scss">

.su-licenses {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 51;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#242424, 0.3);
    backdrop-filter: blur(2.5px);
  }

  .su-licenses__close {
    position: absolute;
    z-index: 3;
    top: calc(var(--scale) * 35);
    right: calc(var(--scale) * 45);
    width: calc(var(--scale) * 33);
    height: calc(var(--scale) * 33);
    border-radius: 50%;
    background-color: #ECEDF0;
    padding: calc(var(--scale) * 10);
    display: grid;
    place-content: center;

    @include media-down($size-tablet) {
      top: 1.7rem;
      right: 1.7rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .su-licenses__slide {
    width: 100%;
    max-width: calc(var(--scale) * 467);
    aspect-ratio: 13 / 19;

    border-radius: 20px;
    filter: drop-shadow(10px 10px 50px rgba(7, 21, 57, 0.1)) drop-shadow(4px 4px 20px rgba(7, 21, 57, 0.05));
    object-fit: cover;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 334);
      filter: none;
    }

    @include media-down($size-mobile) {
      max-width: calc(100vw - var(--scale) * 32);
    }
  }

  .su-licenses__slider {
    max-height: 100vh;
    padding: calc(var(--scale) * 115) calc(var(--scale) * 70);
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(var(--scale) * 20);

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    transition: 300ms;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 80) 0;
    }

    @include media-down($size-mobile) {
      width: 100%;
    }
  }
}

</style>
